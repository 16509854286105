<!-- eslint-disable standard/no-callback-literal -->
<template>
  <div class="row">
    <div class="col-lg-12">
      <a-form-model ref="ruleForm" :model="form">
        <div class="row justify-content-center">
          <div class="card col-8">
            <div v-if="!$route.query.uuid" class="card-header">
              <strong>Add Voucher</strong>
            </div>
            <div class="card-body">
              <a-form-model-item
                prop="voucher_code"
                label="Voucher Code"
                class="col-lg-12 mr-2 ml-2"
                help="Customer will enter this discount code at checkout"
                :rules="[{
                  required: true,
                  message: 'Please input your voucher code!',
                }]"
              >
                <a-input
                  v-if="!$route.query.uuid"
                  v-model="form.voucher_code"
                  placeholder="e.g. PROMO-KEMERDEKAAN"
                />
                <div v-if="$route.query.uuid">
                  <strong> {{ value.voucher_code }} </strong>
                </div>
              </a-form-model-item>
            </div>
          </div>
          <div class="card col-8">
            <div class="card-body">
              <div>
                <a-form-model-item
                  prop="type_id"
                  label="Voucher Type"
                  class="col-lg-12 mr-2 ml-2"
                  has-feedback
                  :rules="[{
                    required: true,
                    message: 'Please select your voucher type!',
                  }]"
                >
                  <a-select
                    v-if="!$route.query.uuid"
                    v-model="form.type_id"
                    placeholder="Select Voucher Type"
                  >
                    <a-select-option :value="1">
                      Transaction Voucher
                    </a-select-option>
                    <a-select-option :value="2">
                      Product Item Voucher
                    </a-select-option>
                    <a-select-option :value="3">
                      Shipping Voucher
                    </a-select-option>
                    <a-select-option :value="4">
                      Voucher Brand
                    </a-select-option>
                  </a-select>
                  <div v-if="$route.query.uuid">
                    <strong> {{ typeVoucher(value?.type_id) }} </strong>
                  </div>
                </a-form-model-item>
              </div>
              <div
                v-if="form.type_id == 2 || (value && value?.type_id == 2)"
                class="ml-2"
              >
                <a-form-model-item
                  prop="product_list"
                  label="Select Products to Get Voucher"
                  has-feddback
                  :rules="[{
                    required: true,
                    message: 'Please select your products!',
                    type: 'array',
                  }]"
                >
                  <a-select
                    v-model="form.product_list"
                    show-search
                    mode="multiple"
                    placeholder="Select Product"
                  >
                    <a-select-option
                      v-for="(product, index) in productList"
                      :key="index"
                      :value="product.product_title + '_' + index"
                    >
                      {{ product.product_title }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </div>
              <div
                v-if="form.type_id == 3 || (value && value?.type_id == 3)"
                class="ml-2"
              >
                <a-form-model-item
                  prop="courier_list"
                  label="Select Courier"
                  :rules="[{
                    required: true,
                    message: 'Please select courier',
                    type: 'array',
                  }]"
                >
                  <a-select
                    v-model="form.courier_list"
                    show-search
                    mode="multiple"
                    placeholder="Select Courier"
                  >
                    <a-select-option
                      v-for="(courier, index) in courierList"
                      :key="index"
                      :value="courier.id"
                    >
                      {{ courier.courier_name }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </div>
              <div
                v-if="form.type_id == 4 || (value && value?.type_id == 4)"
                class="ml-2"
              >
                <a-form-model-item
                  prop="brand_list"
                  label="Select Brands"
                  has-feddback
                  :rules="[{ required: true }]"
                >
                  <a-select
                    v-model="form.brand_list"
                    show-search
                    placeholder="Select Brand"
                  >
                    <a-select-option
                      v-for="(brand, index) in brands"
                      :key="index"
                      :value="`${brand.brand_title}_${index}`"
                    >
                      {{ brand.brand_title }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </div>
            </div>
          </div>
          <div class="card col-8">
            <div class="card-body">
              <div>
                <a-form-model-item
                  prop="discount_type"
                  label="Discount Type"
                  :rules="[{
                    required: true,
                    message: 'Please select discount type',
                  }]"
                >
                  <a-radio-group v-model="form.discount_type">
                    <a-radio
                      v-if="(form.type_id && form.type_id != 3) || (value && value?.type_id != 3)"
                      :value="1"
                    >
                      Percentage
                    </a-radio>
                    <a-radio :value="2">
                      Fixed Amount
                    </a-radio>
                  </a-radio-group>
                </a-form-model-item>
              </div>
              <div>
                <a-form-model-item
                  v-if="form.discount_type == 1 || (value && value.transaction_data && value.transaction_data.type == 1)"
                  prop="discount_value"
                  label="Discount Value"
                  class="col-lg-4"
                  :rules="[{
                    required: true,
                    pattern: new RegExp('^[0-9]*$'),
                    message: 'Discount value must be a number',
                  }]"
                >
                  <a-input v-model="form.discount_value" addon-after="%" />
                </a-form-model-item>
                <a-form-model-item
                  v-if="form.type_id == 3 || form.discount_type == 2 || (value && value.transaction_data && value.transaction_data.type == 2)"
                  prop="discount_value"
                  label="Discount Value"
                  class="col-lg-4"
                  :rules="[{
                    required: true,
                    pattern: new RegExp('^[0-9]*$'),
                    message: 'Discount value must be a number',
                  }]"
                >
                  <a-input v-model="form.discount_value" addon-before="IDR" />
                </a-form-model-item>
              </div>
            </div>
          </div>
          <div class="card col-8">
            <div class="card-body">
              <div>
                <a-form-model-item
                  prop="qty"
                  label="Quantity Voucher"
                  class="col-lg-4"
                  :rules="[{
                    required: true,
                    pattern: new RegExp('^[0-9]*$'),
                    message: 'Quantity value must be a number',
                  }]"
                >
                  <a-input v-model="form.qty" />
                </a-form-model-item>
              </div>
              <div>
                <a-form-model-item
                  prop="min_transaction"
                  label="Minimum Transcation"
                  class="col-lg-4"
                  :rules="[{
                    required: true,
                    pattern: new RegExp('^[0-9]*$'),
                    message: 'Minimum transaction must be a number',
                  }]"
                >
                  <a-input v-model="form.min_transaction" addon-before="IDR" />
                </a-form-model-item>
              </div>
              <div>
                <a-form-model-item
                  prop="max_discount"
                  label="Maximum Discount"
                  class="col-lg-4"
                  :rules="[
                    {
                      required: true,
                      pattern: new RegExp('^[0-9]*$'),
                      message: 'Maximum Discount must be a number',
                    },
                    form.discount_type == 2 && max_discount,
                  ]"
                >
                  <a-input v-model="form.max_discount" addon-before="IDR" />
                </a-form-model-item>
              </div>
            </div>
          </div>
          <div class="card col-8">
            <div class="card-body">
              <div>
                <a-form-model-item
                  prop="f_combined"
                  label="Voucher can combined ?"
                  :rules="[{
                    required: true,
                    message: 'Please check this option',
                  }]"
                >
                  <a-radio-group v-model="form.f_combined">
                    <a-radio :value="true">
                      Yes
                    </a-radio>
                    <a-radio :value="false">
                      No
                    </a-radio>
                  </a-radio-group>
                </a-form-model-item>
              </div>
            </div>
          </div>
          <div class="card col-8">
            <div class="card-body">
              <div>
                <a-form-model-item
                  prop="f_allstore"
                  label="Set Voucher To All Store"
                  :rules="[{
                    required: true,
                    message: 'Please check this option',
                  }]"
                >
                  <a-radio-group v-model="form.f_allstore">
                    <a-radio :value="true">
                      Yes
                    </a-radio>
                    <a-radio :value="false">
                      No
                    </a-radio>
                  </a-radio-group>
                </a-form-model-item>
              </div>
              <div>
                <div
                  v-if="form.f_allstore === false || (value && value.f_allstore === false)"
                  class="ml-2"
                >
                  <a-form-model-item
                    prop="store_list"
                    label="Select Stores to Get Voucher"
                    has-feddback
                    :rules="[{
                      required: true,
                      message: 'Please select store list',
                      type: 'array',
                    }]"
                  >
                    <a-select
                      v-model="form.store_list"
                      mode="multiple"
                      placeholder="Select Store"
                    >
                      <a-select-option
                        v-for="reseller in resellerList"
                        :key="reseller.seller_hashid"
                        :value="reseller.store_name"
                      >
                        {{ reseller.store_name }}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </div>
              </div>
            </div>
          </div>
          <div class="card col-8">
            <div class="card-body">
              <div>
                <a-form-model-item label="Voucher periode" class="ml-2">
                  <a-date-picker
                    v-model="startValue"
                    :disabled-date="disabledStartDate"
                    show-time
                    format="YYYY-MM-DD HH:mm:ss"
                    placeholder="Start"
                    class="mr-2"
                    :disabled="disabledStartValue"
                    @openChange="handleStartOpenChange"
                  />
                  <a-date-picker
                    v-model="endValue"
                    :disabled-date="disabledEndDate"
                    show-time
                    format="YYYY-MM-DD HH:mm:ss"
                    placeholder="End"
                    :open="endOpen"
                    class="ml-2"
                    :disabled="disabledEndValue"
                    @openChange="handleEndOpenChange"
                  />
                </a-form-model-item>
              </div>
              <div>
                <a-form-model-item class="ml-2">
                  <a-button type="primary" @click="handleSubmit">
                    Submit
                  </a-button>
                </a-form-model-item>
              </div>
            </div>
          </div>
        </div>
      </a-form-model>
    </div>
    <div class="vld-parent">
      <loading
        :active.sync="isLoadingDiscount"
        :can-cancel="false"
        :is-full-page="fullPage"
        :height="30"
        :width="30"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Loading from 'vue-loading-overlay'

export default {
  components: {
    Loading,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    value: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    statusVoucher: {
      type: String,
    },
  },
  data() {
    return {
      isAllStore: true,
      isDiscountProduct: false,
      isAllProduct: true,
      isAbsolute: false,
      isPercentage: false,
      fullPage: true,
      showModalProduct: false,
      form: {
        voucher_code: '',
        type_id: '',
        product_list: [],
        courier_list: [],
        brand_list: [],
        discount_type: '',
        discount_value: '',
        qty: '',
        min_transaction: '',
        max_discount: '',
        f_combined: '',
        f_allstore: '',
        store_list: [],
      },
      max_discount: {
        validator: (rule, value, callback) => {
          if (+value > +this.form.discount_value) {
            callback(
              'maximum discount cant be greater than the discounted value',
            )
          } else if (+value < +this.form.discount_value) {
            callback('maximum discount cant be less than the discounted value')
          } else {
            callback()
          }
        },
      },
      initialValueProduct: [],
      initialValueBrand: [],
      initialValueReseller: [],
      inititalValueCourier: [],
      startValue: null,
      endValue: null,
      endOpen: false,
      disabledStartValue: false,
      disabledEndValue: false,
    }
  },
  computed: {
    ...mapState('web_platform_channel/discounts', {
      productList: state => state.productList,
      resellerList: state => state.resellerList,
      courierList: state => state.courierList,
      isLoadingDiscount: state => state.isLoadingDiscount,
      brands: state => state.brands,
    }),
  },
  watch: {
    value: function () {
      const fromDate = this.$moment(this.value.validity.from * 1000).format(
        'YYYY-MM-DD HH:mm:ss',
      )
      const untilDate = this.$moment(this.value.validity.until * 1000).format(
        'YYYY-MM-DD HH:mm:ss',
      )
      this.startValue = this.$moment(fromDate, 'YYYY-MM-DD HH:mm:ss')
      this.endValue = this.$moment(untilDate, 'YYYY-MM-DD HH:mm:ss')

      if (this.value?.type_id === 2) {
        const result = []
        this.productList.map((product, index) => {
          this.value.reference_list.find(item => {
            if (item === product.uuid) {
              result.push(`${product.product_title}_${index}`)
            }
          })
        })
        this.initialValueProduct = result
        this.form.product_list = result
      }

      if (this.value?.type_id === 3) {
        const result = []
        this.courierList.map(courier => {
          this.value.courier_list.find(item => {
            if (item === courier.value) {
              result.push(courier.label)
            }
          })
        })
        this.inititalValueCourier = result
      }

      if (this.value?.type_id === 4) {
        const result = []
        this.brands.map((brand, index) => {
          this.value.reference_list.find(item => {
            if (item === brand.uuid) {
              result.push(`${brand.brand_title}_${index}`)
            }
          })
        })
        this.initialValueBrand = result
      }

      if (!this.value.f_allstore) {
        this.value.store_list.map(item => {
          this.initialValueReseller.push(item.label)
        })
      }

      this.form = {
        voucher_code: this.value?.voucher_code,
        type_id: this.value?.type_id,
        product_list: this.value?.reference_list && this.initialValueProduct,
        courier_list: this.value?.courier_list && this.inititalValueCourier,
        brand_list: this.value?.reference_list && this.initialValueBrand,
        discount_type: this.value?.transaction_data && this.form.type_id != 3
          ? this.value.transaction_data.type
          : this.form.type_id == 3 && 2,
        discount_value: this.value?.transaction_data?.value,
        qty: this.value?.qty,
        min_transaction: this.value?.transaction_data?.min_transaction,
        max_discount: (this.form.discount_type == 2 && this.form.discount_value ? Number(this.form.discount_value) : null)
          || (this.value?.transaction_data?.max_discount?.toString()),
        f_combined: this.value?.f_combined,
        f_allstore: this.value?.f_allstore,
        store_list: this.value?.store_list && this.initialValueReseller,
      }
    },
    statusVoucher: function (newVal) {
      if (newVal === 'running') {
        this.disabledStartValue = true
      }
    },
  },
  mounted() {
    this.$store.dispatch('web_platform_channel/discounts/getProductList', {
      ...this.$route.params,
      ...this.$route.query,
    })
    this.$store.dispatch('web_platform_channel/discounts/getBrands', {
      ...this.$route.params,
      ...this.$route.query,
    })
    this.$store.dispatch('web_platform_channel/discounts/GETCOURIERLIST', {
      ...this.$route.params,
      ...this.$route.query,
    })
  },
  methods: {
    ...mapActions('web_platform_channel/discounts', [
      'getProductList',
      'addDiscountCode',
    ]),
    handleSubmit(e) {
      e.preventDefault()
      if (!this.startValue || !this.endValue) {
        return this.$notification.error({
          message: 'Please input a voucher periode',
        })
      }
      const validity = [this.startValue, this.endValue]
      if (this.$route.query.uuid) {
        this.$refs.ruleForm.validate(valid => {
          if (!valid) return
          this.$store.dispatch('web_platform_channel/discounts/EDITVOUCHER', {
            type_id: this.value?.type_id,
            voucher_code: this.value?.voucher_code,
            validity,
            ...this.form,
            ...this.$route.params,
            ...this.$route.query,
          })
        })
      } else {
        this.$refs.ruleForm.validate(valid => {
          if (!valid) return
          this.$store.dispatch(
            'web_platform_channel/discounts/addDiscountCode',
            {
              validity,
              ...this.form,
              ...this.$route.query,
              ...this.$route.params,
            },
          )
        })
      }
    },
    typeVoucher(value) {
      switch (value) {
        case 1:
          return 'Transaction Voucher'

        case 2:
          return 'Product Item Voucher'

        case 3:
          return 'Shipping Voucher'

        case 4:
          return 'Voucher Brand'

        default:
          break
      }
    },
    showModal() {
      this.showModalProduct = true
    },
    disabledStartDate(startValue) {
      const endValue = this.endValue
      if (!startValue || !endValue) {
        return false
      }
      return (
        startValue.valueOf() > endValue.valueOf() ||
        this.statusVoucher === 'running'
      )
    },
    disabledEndDate(endValue) {
      const startValue = this.startValue
      if (!endValue || !startValue) {
        return false
      }
      return startValue.valueOf() >= endValue.valueOf()
    },
    handleStartOpenChange(open) {
      if (!open) {
        this.endOpen = true
      }
    },
    handleEndOpenChange(open) {
      this.endOpen = open
    },
  },
}
</script>
