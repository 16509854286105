var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('a-form-model',{ref:"ruleForm",attrs:{"model":_vm.form}},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"card col-8"},[(!_vm.$route.query.uuid)?_c('div',{staticClass:"card-header"},[_c('strong',[_vm._v("Add Voucher")])]):_vm._e(),_c('div',{staticClass:"card-body"},[_c('a-form-model-item',{staticClass:"col-lg-12 mr-2 ml-2",attrs:{"prop":"voucher_code","label":"Voucher Code","help":"Customer will enter this discount code at checkout","rules":[{
                required: true,
                message: 'Please input your voucher code!',
              }]}},[(!_vm.$route.query.uuid)?_c('a-input',{attrs:{"placeholder":"e.g. PROMO-KEMERDEKAAN"},model:{value:(_vm.form.voucher_code),callback:function ($$v) {_vm.$set(_vm.form, "voucher_code", $$v)},expression:"form.voucher_code"}}):_vm._e(),(_vm.$route.query.uuid)?_c('div',[_c('strong',[_vm._v(" "+_vm._s(_vm.value.voucher_code)+" ")])]):_vm._e()],1)],1)]),_c('div',{staticClass:"card col-8"},[_c('div',{staticClass:"card-body"},[_c('div',[_c('a-form-model-item',{staticClass:"col-lg-12 mr-2 ml-2",attrs:{"prop":"type_id","label":"Voucher Type","has-feedback":"","rules":[{
                  required: true,
                  message: 'Please select your voucher type!',
                }]}},[(!_vm.$route.query.uuid)?_c('a-select',{attrs:{"placeholder":"Select Voucher Type"},model:{value:(_vm.form.type_id),callback:function ($$v) {_vm.$set(_vm.form, "type_id", $$v)},expression:"form.type_id"}},[_c('a-select-option',{attrs:{"value":1}},[_vm._v(" Transaction Voucher ")]),_c('a-select-option',{attrs:{"value":2}},[_vm._v(" Product Item Voucher ")]),_c('a-select-option',{attrs:{"value":3}},[_vm._v(" Shipping Voucher ")]),_c('a-select-option',{attrs:{"value":4}},[_vm._v(" Voucher Brand ")])],1):_vm._e(),(_vm.$route.query.uuid)?_c('div',[_c('strong',[_vm._v(" "+_vm._s(_vm.typeVoucher(_vm.value?.type_id))+" ")])]):_vm._e()],1)],1),(_vm.form.type_id == 2 || (_vm.value && _vm.value?.type_id == 2))?_c('div',{staticClass:"ml-2"},[_c('a-form-model-item',{attrs:{"prop":"product_list","label":"Select Products to Get Voucher","has-feddback":"","rules":[{
                  required: true,
                  message: 'Please select your products!',
                  type: 'array',
                }]}},[_c('a-select',{attrs:{"show-search":"","mode":"multiple","placeholder":"Select Product"},model:{value:(_vm.form.product_list),callback:function ($$v) {_vm.$set(_vm.form, "product_list", $$v)},expression:"form.product_list"}},_vm._l((_vm.productList),function(product,index){return _c('a-select-option',{key:index,attrs:{"value":product.product_title + '_' + index}},[_vm._v(" "+_vm._s(product.product_title)+" ")])}),1)],1)],1):_vm._e(),(_vm.form.type_id == 3 || (_vm.value && _vm.value?.type_id == 3))?_c('div',{staticClass:"ml-2"},[_c('a-form-model-item',{attrs:{"prop":"courier_list","label":"Select Courier","rules":[{
                  required: true,
                  message: 'Please select courier',
                  type: 'array',
                }]}},[_c('a-select',{attrs:{"show-search":"","mode":"multiple","placeholder":"Select Courier"},model:{value:(_vm.form.courier_list),callback:function ($$v) {_vm.$set(_vm.form, "courier_list", $$v)},expression:"form.courier_list"}},_vm._l((_vm.courierList),function(courier,index){return _c('a-select-option',{key:index,attrs:{"value":courier.id}},[_vm._v(" "+_vm._s(courier.courier_name)+" ")])}),1)],1)],1):_vm._e(),(_vm.form.type_id == 4 || (_vm.value && _vm.value?.type_id == 4))?_c('div',{staticClass:"ml-2"},[_c('a-form-model-item',{attrs:{"prop":"brand_list","label":"Select Brands","has-feddback":"","rules":[{ required: true }]}},[_c('a-select',{attrs:{"show-search":"","placeholder":"Select Brand"},model:{value:(_vm.form.brand_list),callback:function ($$v) {_vm.$set(_vm.form, "brand_list", $$v)},expression:"form.brand_list"}},_vm._l((_vm.brands),function(brand,index){return _c('a-select-option',{key:index,attrs:{"value":`${brand.brand_title}_${index}`}},[_vm._v(" "+_vm._s(brand.brand_title)+" ")])}),1)],1)],1):_vm._e()])]),_c('div',{staticClass:"card col-8"},[_c('div',{staticClass:"card-body"},[_c('div',[_c('a-form-model-item',{attrs:{"prop":"discount_type","label":"Discount Type","rules":[{
                  required: true,
                  message: 'Please select discount type',
                }]}},[_c('a-radio-group',{model:{value:(_vm.form.discount_type),callback:function ($$v) {_vm.$set(_vm.form, "discount_type", $$v)},expression:"form.discount_type"}},[((_vm.form.type_id && _vm.form.type_id != 3) || (_vm.value && _vm.value?.type_id != 3))?_c('a-radio',{attrs:{"value":1}},[_vm._v(" Percentage ")]):_vm._e(),_c('a-radio',{attrs:{"value":2}},[_vm._v(" Fixed Amount ")])],1)],1)],1),_c('div',[(_vm.form.discount_type == 1 || (_vm.value && _vm.value.transaction_data && _vm.value.transaction_data.type == 1))?_c('a-form-model-item',{staticClass:"col-lg-4",attrs:{"prop":"discount_value","label":"Discount Value","rules":[{
                  required: true,
                  pattern: new RegExp('^[0-9]*$'),
                  message: 'Discount value must be a number',
                }]}},[_c('a-input',{attrs:{"addon-after":"%"},model:{value:(_vm.form.discount_value),callback:function ($$v) {_vm.$set(_vm.form, "discount_value", $$v)},expression:"form.discount_value"}})],1):_vm._e(),(_vm.form.type_id == 3 || _vm.form.discount_type == 2 || (_vm.value && _vm.value.transaction_data && _vm.value.transaction_data.type == 2))?_c('a-form-model-item',{staticClass:"col-lg-4",attrs:{"prop":"discount_value","label":"Discount Value","rules":[{
                  required: true,
                  pattern: new RegExp('^[0-9]*$'),
                  message: 'Discount value must be a number',
                }]}},[_c('a-input',{attrs:{"addon-before":"IDR"},model:{value:(_vm.form.discount_value),callback:function ($$v) {_vm.$set(_vm.form, "discount_value", $$v)},expression:"form.discount_value"}})],1):_vm._e()],1)])]),_c('div',{staticClass:"card col-8"},[_c('div',{staticClass:"card-body"},[_c('div',[_c('a-form-model-item',{staticClass:"col-lg-4",attrs:{"prop":"qty","label":"Quantity Voucher","rules":[{
                  required: true,
                  pattern: new RegExp('^[0-9]*$'),
                  message: 'Quantity value must be a number',
                }]}},[_c('a-input',{model:{value:(_vm.form.qty),callback:function ($$v) {_vm.$set(_vm.form, "qty", $$v)},expression:"form.qty"}})],1)],1),_c('div',[_c('a-form-model-item',{staticClass:"col-lg-4",attrs:{"prop":"min_transaction","label":"Minimum Transcation","rules":[{
                  required: true,
                  pattern: new RegExp('^[0-9]*$'),
                  message: 'Minimum transaction must be a number',
                }]}},[_c('a-input',{attrs:{"addon-before":"IDR"},model:{value:(_vm.form.min_transaction),callback:function ($$v) {_vm.$set(_vm.form, "min_transaction", $$v)},expression:"form.min_transaction"}})],1)],1),_c('div',[_c('a-form-model-item',{staticClass:"col-lg-4",attrs:{"prop":"max_discount","label":"Maximum Discount","rules":[
                  {
                    required: true,
                    pattern: new RegExp('^[0-9]*$'),
                    message: 'Maximum Discount must be a number',
                  },
                  _vm.form.discount_type == 2 && _vm.max_discount,
                ]}},[_c('a-input',{attrs:{"addon-before":"IDR"},model:{value:(_vm.form.max_discount),callback:function ($$v) {_vm.$set(_vm.form, "max_discount", $$v)},expression:"form.max_discount"}})],1)],1)])]),_c('div',{staticClass:"card col-8"},[_c('div',{staticClass:"card-body"},[_c('div',[_c('a-form-model-item',{attrs:{"prop":"f_combined","label":"Voucher can combined ?","rules":[{
                  required: true,
                  message: 'Please check this option',
                }]}},[_c('a-radio-group',{model:{value:(_vm.form.f_combined),callback:function ($$v) {_vm.$set(_vm.form, "f_combined", $$v)},expression:"form.f_combined"}},[_c('a-radio',{attrs:{"value":true}},[_vm._v(" Yes ")]),_c('a-radio',{attrs:{"value":false}},[_vm._v(" No ")])],1)],1)],1)])]),_c('div',{staticClass:"card col-8"},[_c('div',{staticClass:"card-body"},[_c('div',[_c('a-form-model-item',{attrs:{"prop":"f_allstore","label":"Set Voucher To All Store","rules":[{
                  required: true,
                  message: 'Please check this option',
                }]}},[_c('a-radio-group',{model:{value:(_vm.form.f_allstore),callback:function ($$v) {_vm.$set(_vm.form, "f_allstore", $$v)},expression:"form.f_allstore"}},[_c('a-radio',{attrs:{"value":true}},[_vm._v(" Yes ")]),_c('a-radio',{attrs:{"value":false}},[_vm._v(" No ")])],1)],1)],1),_c('div',[(_vm.form.f_allstore === false || (_vm.value && _vm.value.f_allstore === false))?_c('div',{staticClass:"ml-2"},[_c('a-form-model-item',{attrs:{"prop":"store_list","label":"Select Stores to Get Voucher","has-feddback":"","rules":[{
                    required: true,
                    message: 'Please select store list',
                    type: 'array',
                  }]}},[_c('a-select',{attrs:{"mode":"multiple","placeholder":"Select Store"},model:{value:(_vm.form.store_list),callback:function ($$v) {_vm.$set(_vm.form, "store_list", $$v)},expression:"form.store_list"}},_vm._l((_vm.resellerList),function(reseller){return _c('a-select-option',{key:reseller.seller_hashid,attrs:{"value":reseller.store_name}},[_vm._v(" "+_vm._s(reseller.store_name)+" ")])}),1)],1)],1):_vm._e()])])]),_c('div',{staticClass:"card col-8"},[_c('div',{staticClass:"card-body"},[_c('div',[_c('a-form-model-item',{staticClass:"ml-2",attrs:{"label":"Voucher periode"}},[_c('a-date-picker',{staticClass:"mr-2",attrs:{"disabled-date":_vm.disabledStartDate,"show-time":"","format":"YYYY-MM-DD HH:mm:ss","placeholder":"Start","disabled":_vm.disabledStartValue},on:{"openChange":_vm.handleStartOpenChange},model:{value:(_vm.startValue),callback:function ($$v) {_vm.startValue=$$v},expression:"startValue"}}),_c('a-date-picker',{staticClass:"ml-2",attrs:{"disabled-date":_vm.disabledEndDate,"show-time":"","format":"YYYY-MM-DD HH:mm:ss","placeholder":"End","open":_vm.endOpen,"disabled":_vm.disabledEndValue},on:{"openChange":_vm.handleEndOpenChange},model:{value:(_vm.endValue),callback:function ($$v) {_vm.endValue=$$v},expression:"endValue"}})],1)],1),_c('div',[_c('a-form-model-item',{staticClass:"ml-2"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v(" Submit ")])],1)],1)])])])])],1),_c('div',{staticClass:"vld-parent"},[_c('loading',{attrs:{"active":_vm.isLoadingDiscount,"can-cancel":false,"is-full-page":_vm.fullPage,"height":30,"width":30},on:{"update:active":function($event){_vm.isLoadingDiscount=$event}}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }